import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuardBusiness } from 'src/auth/guard';
// layouts
import ManagementLayout from 'src/layouts/management';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------
const BranchListPage = lazy(() => import('src/pages/management/branch/list'));
const BranchDetailsPage = lazy(() => import('src/pages/management/branch/details'));
const BranchCreatePage = lazy(() => import('src/pages/management/branch/create'));
const BranchEditPage = lazy(() => import('src/pages/management/branch/edit'));
const PosDeviceNewPage = lazy(() => import('src/pages/management/pos-device/new'));
const PosDeviceListPage = lazy(() => import('src/pages/management/pos-device/list'));
const PosDeviceDetailsPage = lazy(() => import('src/pages/management/pos-device/details'));
const PosDeviceReSignInPage = lazy(() => import('src/pages/management/pos-device/re-sign-in'));
const PosDeviceEditPage = lazy(() => import('src/pages/management/pos-device/edit'));
const BusinessItemCreatePage = lazy(() => import('src/pages/management/business-item/create'));
const BusinessItemListPage = lazy(() => import('src/pages/management/business-item/list'));
const BusinessItemDetailsPage = lazy(() => import('src/pages/management/business-item/details'));
const BusinessItemEditPage = lazy(() => import('src/pages/management/business-item/edit'));
const BusinessOverviewPage = lazy(() => import('src/pages/management/business-overview/details'));
const CategoryCreatePage = lazy(() => import('src/pages/management/category/create'));
const CategoryListPage = lazy(() => import('src/pages/management/category/list'));
const CategoryEditPage = lazy(() => import('src/pages/management/category/edit'));
const CategoryDetailsPage = lazy(() => import('src/pages/management/category/details'));
const CountryListPage = lazy(() => import('src/pages/management/country/list'));
const CountryCreatePage = lazy(() => import('src/pages/management/country/create'));
const CountryDetailsPage = lazy(() => import('src/pages/management/country/details'));
const CountryEditPage = lazy(() => import('src/pages/management/country/edit'));

const RegionListPage = lazy(() => import('src/pages/management/region/list'));
const RegionCreatePage = lazy(() => import('src/pages/management/region/create'));
const RegionDetailsPage = lazy(() => import('src/pages/management/region/details'));
const RegionEditPage = lazy(() => import('src/pages/management/region/edit'));

// ----------------------------------------------------------------------

export const managementRoutes = [
  {
    path: 'management',
    element: (
      <AuthGuardBusiness>
        <ManagementLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </ManagementLayout>
      </AuthGuardBusiness>
    ),
    children: [
      { element: <BusinessOverviewPage />, index: true },
      {
        path: 'branch',
        children: [
          { element: <BranchListPage />, index: true },
          { path: 'list', element: <BranchListPage /> },
          {
            path: ':id',
            children: [
              { element: <BranchDetailsPage />, index: true },
              { path: 'pos-device', element: <BranchDetailsPage /> },
              { path: 'table', element: <BranchDetailsPage /> },
              // { path: 'report', element: <BranchDetailsPage /> },
              { path: 'sale-summary', element: <BranchDetailsPage /> },
              { path: 'edit', element: <BranchEditPage /> },
              { path: 'settings', element: <BranchDetailsPage /> },
            ],
          },
          { path: 'create', element: <BranchCreatePage /> },
        ],
      },
      {
        path: 'pos-device',
        children: [
          { element: <PosDeviceListPage />, index: true },
          { path: 'list', element: <PosDeviceListPage /> },
          { path: ':id', element: <PosDeviceDetailsPage /> },
          { path: 'create', element: <PosDeviceNewPage /> },

          { path: ':id/re-sign-in', element: <PosDeviceReSignInPage /> },
          { path: ':id/edit', element: <PosDeviceEditPage /> },
        ],
      },
      {
        path: 'business-item',
        children: [
          { element: <BusinessItemListPage />, index: true },
          { path: 'list', element: <BusinessItemListPage /> },
          {
            path: ':id',
            children: [
              {
                element: <BusinessItemDetailsPage />,
                index: true,
              },
              { path: 'edit', element: <BusinessItemEditPage /> },
            ],
          },
          { path: 'create', element: <BusinessItemCreatePage /> },
        ],
      },
      {
        path: 'category',
        children: [
          { element: <CategoryListPage />, index: true },
          { path: 'list', element: <CategoryListPage /> },
          { path: 'create', element: <CategoryCreatePage /> },
          {
            path: ':id',
            children: [
              {
                element: <CategoryDetailsPage />,
                index: true,
              },
              { path: 'edit', element: <CategoryEditPage /> },
            ],
          },
        ],
      },
      {
        path: 'country',
        children: [
          { element: <CountryListPage />, index: true },
          { path: 'list', element: <CountryListPage /> },
          { path: 'create', element: <CountryCreatePage /> },
          {
            path: ':id',
            children: [
              { element: <CountryDetailsPage />, index: true },
              { path: 'edit', element: <CountryEditPage /> },
            ],
          },
        ],
      },
      {
        path: 'region',
        children: [
          { element: <RegionListPage />, index: true },
          { path: 'list', element: <RegionListPage /> },
          { path: 'create', element: <RegionCreatePage /> },
          {
            path: ':id',
            children: [
              { element: <RegionDetailsPage />, index: true },
              { path: 'edit', element: <RegionEditPage /> },
            ],
          },
        ],
      },
    ],
  },
];
